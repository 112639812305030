var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"max-width":"500px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.loading)?_c('div',{staticClass:"text-center",staticStyle:{"min-height":"500px"}},[_c('v-progress-circular',{staticStyle:{"text-align":"center","padding-top":"20px","padding-bottom":"20px","margin-top":"200px"},attrs:{"size":100,"width":10,"color":"#7253CF","indeterminate":""}})],1):_vm._e(),(!_vm.loading)?_c('v-card',{attrs:{"background-color":"#f2f2f2"}},[_c('v-card-title',{staticClass:"py-0 pt-5",staticStyle:{"background-color":"#7253cf","color":"white"}},[_c('div',{staticClass:"d-flex px-1"},[_c('img',{attrs:{"src":require("@/assets/basketLogoDialogDistrictManagement.svg"),"height":"85","width":"100"}})]),_c('div',{staticClass:"ml-1 dialogTitle-text"},[_vm._v("Custom Event Info")]),_c('v-spacer'),_c('v-icon',{staticStyle:{"cursor":"pointer","position":"relative","right":"-15px","bottom":"40px"},attrs:{"text":"","size":"27","color":"white"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({ show: false })}}},[_vm._v("mdi-close ")])],1),_c('v-card-text',[_c('div',{staticClass:"text-center"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px"}},[_c('hr',{staticStyle:{"height":"2px","border-width":"0","'\n                  color":"gray","background-color":"gray"}}),_vm._v(" "+_vm._s(this.customDetail.date_str)+" "+_vm._s(_vm.converTime(this.customDetail.start_time))+"-"+_vm._s(_vm.converTime(this.customDetail.end_time))+" "),_c('v-icon',{staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"22"},on:{"click":function($event){return _vm.toggleAddEventModal({
                  show: true,
                  type: 'view',
                  id: _vm.customDetail.id,
                })}}},[_vm._v(" mdi-pencil ")]),(this.adminAccess != 'Player')?_c('v-icon',{staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"22"},on:{"click":function($event){return _vm.toggleDeleteCustomEventModal({
                  Delete: true,
                  id: _vm.customDetail.id,
                })}}},[_vm._v(" mdi-delete ")]):_vm._e(),_c('hr',{staticStyle:{"height":"2px","border-width":"0","color":"gray","background-color":"gray"}}),_c('br'),(this.customDetail.player_confirmations.length != 0)?_c('div',[_c('strong',[_vm._v("Players: ")]),_vm._l((_vm.customplayeritems1),function(monthlySlot,index){return _c('div',{key:index},[_c('tr',{staticClass:"students"},[_c('td',[_vm._v(_vm._s(monthlySlot.player_name))]),_vm._v("    "),(monthlySlot.confirmation_status == 'confirmed')?_c('td',{staticStyle:{"color":"green","font-weight":"600"}},[_vm._v(" Confirmed ")]):_c('td',{staticStyle:{"color":"red","font-weight":"600"}},[_vm._v(" Not Confirmed ")])])])})],2):_vm._e(),_c('br'),(this.customDetail.player_confirmations.length == 0)?_c('div',[_c('tr',{staticClass:"students"},[_c('strong',[_vm._v("Players: ")]),_vm._l((_vm.customplayeritems),function(item,i){return _c('td',{key:i,staticClass:"student show",domProps:{"textContent":_vm._s(item.name)}})})],2),_c('br')]):_vm._e(),_c('strong',[_vm._v("Category: ")]),_vm._v("Custom Event "),_c('br'),_c('br'),_c('strong',[_vm._v("Meeting Place: ")]),_vm._v(" "+_vm._s(this.customDetail.school)+" "),_c('br'),_c('strong',[_vm._v("Meeting Time: ")]),_vm._v(" "+_vm._s(this.customDetail.meeting_time_str)+" "),_c('br'),_c('strong',[_vm._v("Meeting Address: ")]),_vm._v(" "+_vm._s(this.customDetail.address)+" "),_c('br'),_c('strong',[_vm._v("Description: ")]),_c('div',{domProps:{"innerHTML":_vm._s(this.customDetail.note)}}),_c('br'),_c('strong',[_vm._v("Contact Name: ")]),_vm._v(_vm._s(this.customDetail.contact_name)+" "),_c('br'),_c('strong',[_vm._v("Contact Number: ")]),_vm._v(_vm._s(this.customDetail.phone)+" "),_c('br'),_c('strong',[_vm._v("Sales Rep: ")]),_vm._v(" "+_vm._s(this.customDetail.sales_rep_name)+" "),_c('hr',{staticStyle:{"height":"2px","border-width":"0","color":"gray","background-color":"gray"}})],1)])])],1):_vm._e()],1),_c('add-event-modal'),_c('delete-custom-event-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }